.Contact-page {
    margin-top: 64px;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.contact-container {
    display: flex;
}

.Contact-map {
    width: 50%;
    height: 100vh;
    min-width: 0px;
}

.contact-content {
    width: 50vw;
    min-width: 400px;
    height: 100vh;
    background-color: #382D40;
    padding: 13px 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    box-sizing: border-box;
}

.contact-content-wrap {
    width: 100%;
    height: 100%;
    padding: 0px 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-start {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contact-head {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    h1{
        margin: 0;
        font-style: normal;
        font-family: Kanit, sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #FFFFFF;
        text-align: left;
    }
}

.contact-box {
    max-width: 554px;
    width: 95%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    margin-top: 16px;
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr 8fr;
    align-items: center;
    justify-items: center;
}

.contact-box-logo {
    max-width: 554px;
    width: 95%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1.0);
    margin-top: 16px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    img {
        height: 40px;
    }
}

.contact-box > :nth-child(2) {
    justify-self: start;
}

.contact-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #382D40 ;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-text {
    display: flex;
    justify-content: flex-start;
    padding-left: 16px;

    p {
        font-style: normal;
        font-family: Kanit, sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #FFFFFF;
        margin: 0;
    }
}
