.css-5rg2mi-MuiTableCell-root.MuiTableCell-head {
    background-color: #8c4ab4 !important;
}

.css-lid11g-MuiTableCell-root.MuiTableCell-head {
    background-color: #8040a8 !important;
}

.css-1enotpu-MuiTableRow-root:nth-of-type(odd) {
    background: rgba(236, 214, 248, 0.5) !important;
}

.css-1enotpu-MuiTableRow-root:nth-of-type(odd):hover {
    background-color: rgba(228, 177, 243, 0.3) !important;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.css-1enotpu-MuiTableRow-root:hover {
    background-color: rgba(255, 236, 255, 0.3) !important;
    transition: background-color 0.5s ease, box-shadow 0.5s ease;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
